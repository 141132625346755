<script setup>
import BreadCrumbs from "@/components/block/BreadCrumbs";
import {useVuelidate} from "@vuelidate/core";
import {required, email, minLength, helpers, numeric} from '@vuelidate/validators'
import {createToaster} from "@meforma/vue-toaster";
import {onMounted, ref} from 'vue'
import questionService  from "@/services/api/question.service";

const outLinks = [
  {
    href: 'question',
    name: 'Задать вопрос ветеринару'
  }
]
const toaster = createToaster({ /* options */});

const loadQuestion = async () => await questionService.get(0)

const qustions = ref([])

onMounted(async () => {
  qustions.value = await loadQuestion()
})

const activeTab = ref(1)

const form = ref({
  name: '',
  email: '',
  body: '',
})

const validations = {
  name: {
    required: helpers.withMessage('Обязательно к заполнению', required),
    minLength: helpers.withMessage('От трех знаков', minLength(3))
  },
  email: {
    email: helpers.withMessage('Неверный формат почты', email),
    required: helpers.withMessage('Обязательно к заполнению', required),
  },
  body: {
    required: helpers.withMessage('Обязательно к заполнению', required)
  }
}

const v$ = useVuelidate(validations, form)

async function send(form)
{
  const isFormCorrect = await v$.value.$validate()
  if (!isFormCorrect) return

  form.name = ''
  form.email = ''
  form.body = ''

  toaster.success('Ваш вопрос получен, ожидайте ответа')
}

const openAnswer = ref(0)

function toogleNews(id)
{
  if(openAnswer.value === id){
    openAnswer.value = 0
  }else{
    openAnswer.value = id
  }

}

</script>

<template>
  <main class="main">

    <BreadCrumbs :out-links="outLinks"/>

    <section class="vet section">
      <div class="container">
        <h2 class="vet__title">Спроси ветеринара!</h2>
        <picture>
          <source srcset="img/vet-bg-little.png" media="(max-width: 575px)">
          <img class="vet__img" src="img/vet-bg.png" width="1158" height="430" alt="">
        </picture>
        <p class="vet__description">На вопросы отвечает ветеринарный врач-терапевт Гаврилова Анастасия</p>
        <div class="vet__tabs tabs">
          <div class="tabs__controls">
            <span class="tabs__controls-tab"
                  @click="activeTab=1"
                  :class="{'tabs__controls-tab_active': activeTab === 1}"
            >Задать вопрос</span>
            <span class="tabs__controls-tab"
                  @click="activeTab=2"
                  :class="{'tabs__controls-tab_active': activeTab === 2}"
            >Ответы</span>
          </div>
          <div class="tabs__body">
            <div class="tabs__content"
                 :class="{'tabs__content_active': activeTab === 1}">
              <div class="section-header">
                <h2 class="section-header__title">Задайте свой вопрос</h2>
              </div>
              <form class="vet__form" action="javascript:void(0)" method="post">
                <div class="vet__form-controls">
                  <input class="vet__form-input" type="text" name="name" placeholder="Ваше имя"
                         v-model.trim="form.name"
                         :class="{'error':  (v$.name.$dirty && v$.name.$invalid)}"
                  >
                  <input class="vet__form-input" type="email" name="email" placeholder="E-mail*"
                         v-model.trim="form.email"
                         :class="{'error':  (v$.email.$dirty && v$.email.$invalid)}">
                  <!--                  <div class="vet__form-select select">
                                      <select>
                                        <option value="" disabled selected>Тема вопроса</option>
                                        <option value="">Последние 6 месяцев</option>
                                        <option value="">Последний месяц</option>
                                        <option value="">Длинный текст месяца месяца</option>
                                      </select>
                                    </div>-->
                  <textarea class="vet__form-textarea" name="question" placeholder="Вопрос"
                            v-model.trim="form.body"
                            :class="{'error':  (v$.body.$dirty && v$.body.$invalid)}"></textarea>
                </div>
                <p class="blue">Заполните обязательные поля</p>
                <p class="single">*На указанный адрес электронной почты придёт уведомление об ответе от ветеринара.</p>
                <button @click="send(form)" class="vet__form-btn btn">Отправить вопрос</button>
              </form>
            </div>
            <div class="tabs__content"
                 :class="{'tabs__content_active': activeTab === 2}"
            >
              <div class="section-header section-header_low-bottom-margin">
                <h2 class="section-header__title">Вы спрашивали</h2>
              </div>
<!--              <form class="filter">
                <div class="filter__item" style="flex-grow: 0">359 вопросов</div>
                <div class="filter__item" style="flex-grow: 1">
                  <input type="text" placeholder="Поиск">
                  <svg width="16" height="16">
                    <use href="img/icons.svg#search" fill="#8C8C8E"></use>
                  </svg>
                </div>
                <div class="filter__item" style="flex-grow: 0">
                  <div class="form__checkbox" style="min-width: 150px">
                    <label class="form__checkbox-label" style="white-space: nowrap" for="only-new">Только новые</label>
                    <input type="checkbox" id="only-new">
                    <label class="form__checkbox-custom" for="only-new"></label>
                  </div>
                </div>
                <div class="select filter__item">
                  <select>
                    <option value="" disabled selected>Тема вопроса</option>
                    <option value="">Последние 6 месяцев</option>
                    <option value="">Последний месяц</option>
                    <option value="">Длинный текст месяца месяца</option>
                  </select>
                </div>
              </form>-->

              <div class="faq-list faq-list_dropdown" v-show="qustions.length > 0">

                <div class="faq-list__item" v-for="item in qustions" :key="item.id"
                     :class="{active: openAnswer === item.id}">
                  <div class="faq-list__item-header" @click="toogleNews(item.id)">
                    <span class="faq-list__item-tag">{{ item.autor }}</span>
                    <p class="faq-list__item-title">{{ item.body }}</p>
                  </div>
                  <div class="faq-list__item-body" v-html="item.answer.body">

                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </section>


  </main>
</template>


<style scoped>

</style>
