
const tokenService = {
  async setToken (value)
  {
    localStorage.setItem('authToken_amigo_'+process.env.NODE_ENV, value)
  },
  async getToken ()
  {
    if(localStorage.getItem('authToken_amigo_'+process.env.NODE_ENV) !== undefined){
      return localStorage.getItem('authToken_amigo_'+process.env.NODE_ENV)
    }
    return null
  }
}

export default tokenService
