<script setup>
import {defineProps, defineEmits} from 'vue'

defineProps({
  review:{
    type:Object
  }
})

const emits = defineEmits([
  'readId'
])

function toggle()
{
  emits('readId', true)
}

function fullPath(name)
{
  return process.env.VUE_APP_API_URL + '/images/review/' + name
}

</script>

<template>
  <div class="faq-list__item-header" @click="toggle">
    <span class="faq-list__item-tag">{{ review.author }}</span>
    <p class="faq-list__item-title">{{ review.head }}</p>
  </div>
  <div class="faq-list__item-body">
    <p v-html="review.body"></p>
    <p class="image_news"><img :src="fullPath(item.name)"
                               v-for="item in review.image" :key="item.name"

    ></p>
  </div>
</template>



<style scoped lang="scss">
  .image_news{
      img{
        height: 200px;
        padding: 3px;
      }
  }
</style>
