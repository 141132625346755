import {defineStore} from 'pinia'
import navService from "@/services/nav.service";

export const useNavStore = defineStore('navStore', {
    state: () => ({
        basic: [], catalog: [], other: []
    }), persist: false, getters: {
        getCatalog() {
            if (this.catalog.length === 0) {
                this.getCatalogMenu()
            }
            return this.catalog
        }, getBasic() {
            if (this.basic.length === 0) {
                this.getBasicMenu()
            }
            let check = this.basic.find(el => el.did !== undefined)
            return this.basic
        }, getOther() {
            if (this.other.length === 0) {
                return this.getOtherMenu()
            }
            return this.other
        }
    }, actions: {
        async getBasicMenu() {
            this.basic = await navService.basicBar()
        },

        async getCatalogMenu() {
            this.catalog = await navService.catalogBar()
        },

        async getOtherMenu() {
            this.other = await navService.otherBar()
        }
    }
})
