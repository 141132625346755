<script setup>
import BreadCrumbs from "@/components/block/BreadCrumbs";
import {articleService} from "@/services/article.service";
import {onMounted, ref} from "vue";
import ArticleCard from "@/components/block/ArticleCard";

const title = 'Полезные статьи'

const outLinks = [
  {name: title, href:"article"}
]


const loadArticle = async () => await articleService.list()

const articles = ref([])

onMounted(async() => {
    articles.value = await loadArticle()
})

</script>

<template>
    <main class="main">

      <BreadCrumbs :outLinks="outLinks"  />

      <section class="articles section">
        <div class="container">
          <div class="section-header section-header_low-bottom-margin">
            <h2 class="section-header__title">{{ title }}</h2>
          </div>

          <div class="articles-list">
            <div class="article-list__item"
              v-for="item in articles" :key="item.id">
                <ArticleCard :article="item" />
            </div>
          </div>
        </div>
      </section>

<!--      <div class="pagination">
        <a class="pagination__item pagination__item_prev" href="">Назад</a>
        <a class="pagination__item active" href="">1</a>
        <a class="pagination__item" href="">2</a>
        <a class="pagination__item" href="">3</a>
        <a class="pagination__item pagination__item_next" href="">Вперед</a>
      </div>-->
    </main>
</template>



<style scoped lang="scss">

</style>
