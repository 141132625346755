<script setup>
import {useBasketStore} from "@/stores/basketStore";
import { defineProps, ref, defineEmits } from 'vue'
import {createToaster} from "@meforma/vue-toaster";
import { productHelper } from "@/components/helpers/product.helper";

const toaster = createToaster({ /* options */ });

const cart = useBasketStore()

const props = defineProps({
    product: {
      type: Object,
      default: {}
    },
    show: {
       type: Boolean,
       default: false
    }
})

const currentPrice = ref({
  price: 0,
  basicPrice: 0,
  discount: 0
})

const emits = defineEmits({
    close
})

const activeModification = ref(0)
const count = ref(1)
const activeProduct = ref({})

function closeModal()
{
  emits('close', false)
}

function fullHref(){
  return process.env.VUE_APP_API_URL + '/images/catalog/'
}

function mergeAttr(params, unit)
{
  const str = params.map(item => item.value !== null ? item.value : item.value_int + unit)

  return str.join();
}

function selectModify(id)
{
  activeModification.value = id
  activeProduct.value = props.product.price.find(el => el.id === id)
  currentPrice.value = productHelper.discountPrice(activeProduct.value)
}

function editCount(num)
{
  count.value += num
  if(count.value <= 0){
    count.value = 1
  }
}

function addBasket()
{
  if(Object.keys(activeProduct.value).length === 0){
    toaster.error(`Необходимо выбрать вариант продукта`);
    return
  }
  cart.addCart(activeProduct.value, count.value)
  toaster.success('Товар добавлен в корзину')
  closeModal()
}

</script>

<template>
  <div id="modal-product"
       class="modal__product modal"
       :class="{active : show}"
  >
    <div class="modal-content">
      <div class="modal__header">
        <p class="title">
          <span class="blue">{{ product.vendor_name }}</span><br>
          {{ product.name }} <br>
          <span class="single"></span>
        </p>
             <span @click="closeModal" class="modal__close" title="Закрыть">
             <svg width="25" height="25" fill="#000000">
             <use href="/img/icons.svg#times"></use>
             </svg>
             </span>
      </div>
      <div class="modal__middle">
        <div class="img-block">
          <img :src=" fullHref() + product.img" :alt="product.name" width="207" height="263" alt="">
        </div>
        <div class="info-block">
          <div class="tags-list">
            <div class="tag"
                 v-for="modifyItem in product.price"
                 :key="modifyItem.id"
                 @click="selectModify(modifyItem.id)"
                 :class="{'activePrice': modifyItem.id === activeModification}"
            >
              <span class="color" style="background-color: #000000"></span>{{ mergeAttr(modifyItem.attributesSets, modifyItem.unit) }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal__bottom">
        <div class="details">
<!--          <div class="details-col details_key">Вес:</div>
          <div class="details-col details_value">150 г.</div>
          <div class="details-col details_key">Цена</div>
          <div class="details-col details_value">750 ₽</div>
          <div class="details-col details_key">Объем:</div>
          <div class="details-col details_value">150 гр</div>-->
        </div>
        <div class="product__btns">
          <div class="product__btn-count-group">
            <button class="count-btn count-decrease" @click="editCount(-1)">-</button>
            <input class="count" type="text" v-model="count">
            <button class="count-btn count-increase" @click="editCount(1)" >+</button>
          </div>
          <a class="product__btn-basket"
             href="javascript:void(0)"
             @click="addBasket"
          >{{ currentPrice.price }} ₽</a>
          <span v-if="currentPrice.discount !== 0" class="product__other"> {{ currentPrice.discount }}% <br> <s>{{ currentPrice.basicPrice }} ₽</s></span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .active{
    display: block;
  }

  .modal-content{
    margin-top: 10px;
  }
  .modal__product .modal-content{
      padding-top: 20px;
  }
  .tag:hover{
    color: #ffffff;
    background-color: #8C8C8E;
    cursor: pointer;
  }
  .tags-list .activePrice{
    color: #ffffff;
    border-color: #2ea6e5;
    background-color: #2ea6e5;
  }
</style>
