<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectFade, Navigation, Autoplay } from 'swiper'
import "swiper/css/effect-fade";
import sliderService from "@/services/slider.service";
import {onMounted, ref} from "vue";

const url = process.env.VUE_APP_API_URL

const sliders = ref([])

const loadSlider = async () =>  sliderService.getAll()

onMounted(async () => {
  sliders.value = await loadSlider()
})

const onSwiper = (swiper) => swiper;
const onSlideChange = () => console.log('slide change');
const modules = [ EffectFade, Navigation, Autoplay ];

</script>

<template>
  <section class="main-slider section" style="margin-bottom: 10px">
    <div class="container">
      <swiper
        :slides-per-view="1"
        :space-between="0"
        :autoplay="{
          delay:30000
        }"
        :navigation="{
          clickable: true,
        }"
        :modules="modules"
        :effect="'fade'"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        class="swiper main-slider-swiper">
        <swiper-slide
          v-for="slider in sliders"
          class="swiper-slide"
          :key="slider.num">
          <img :src="url + '/images/slider/' + slider.img_file" alt="">
        </swiper-slide>
      </swiper>

      <!--        -->
      <!--        <div class="swiper-button swiper-button_next">
                <svg width="20" height="20">
                  <use xlink:href="img/icons.svg#arrow-down"></use>
                </svg>
              </div>
              <div class="swiper-button swiper-button_prev">
                <svg width="20" height="20">
                  <use xlink:href="/img/icons.svg#arrow-down"></use>
                </svg>
              </div>-->
    </div>
  </section>
</template>

<style scoped>

</style>
