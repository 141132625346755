import {httpService} from "@/services/api/http.service";

const videoService = {
  async list(id){
    const result = await httpService.get('video/index?ofset='+ id);

    if(result.status === 200){
      return result.data
    }
    return []
  }
}
export default videoService
