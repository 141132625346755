<script setup>
import {contentService} from "@/services/content.service";
import {onMounted, ref} from 'vue'
import BreadCrumbs from "@/components/block/BreadCrumbs";

const loadContent = async () => await contentService.getSectionContent('partner')

const content = ref('')

onMounted(async () => {
    content.value = await loadContent()
})

const outLinks = [
  { name: 'Партнерам', href: '/partner' }
];

</script>

<template>
  <BreadCrumbs :out-links="outLinks" />
  <section class="content">
    <div class="container">
      <div class="alert alert-success">
        <h3>Рационы AmiGold - полнорационные корма супер-премиум класса для кошек и собак.</h3>
        Наши рационы это:
        <ul class="list-group">
          <li class="list-group-item">качественное сырье;</li>
          <li class="list-group-item">современное оборудование, отвечающее всем необходимым стандартам;</li>
          <li class="list-group-item">сбалансированная рецептура, разработанная с учетом всех потребностей питомцев;</li>
          <li class="list-group-item">оптимальное соотношение цены и качества;</li>
        </ul>
      </div>

      <h2>Мы рады новым друзьям! </h2>
      <h3>Варианты сотрудничества:</h3>
      <div class="row">
        <div class="col-sm-6">
          <div class="card" style="width: 38rem;">
            <img src="https://amigovetpet.ru/images/stm_small.png">
            <div class="card-body">
              <h2 class="card-title">Создадим корм под вашей торговой маркой(СТМ)</h2>
              <p class="card-text">Хотите запустить свой продукт на рынок? Мы поможем избежать хлопот и излишних денежных затрат!
                Просто свяжитесь с нами, и мы построим производственный процесс от создания рецептуры до упаковки под
                любой ваш запрос::</p>
              <ul class="list-group card-text">
                <li>Разработаем уникальную рецептуру;</li>
                <li>Изготовим корм, соответствующий всем мировым стандартам;</li>
                <li>Обеспечим контроль качества на всех этапах производства;</li>
                <li>Разработаем дизайн упаковки;</li>
                <li>Проконсультируем в вопросах продвижения продукта;</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card" style="width: 38rem;">
            <img src="https://amigovetpet.ru/images/amb_small.png">
            <div class="card-body">
              <h2 class="card-title">Станьте амбассадорами нашего бренда!</h2>
              <p class="card-text">Приглашаем четверолапых звезд Интернета, активных пользователей социальных сетей и блогеров:</p>
              <ul class="list-group card-text">
                <li>от нас: индивидуальная система скидок на рационы и комплементарная продукция</li>
                <li>от вас: честные отзывы о наших рационах.</li>
              </ul>
              <p class="card-text">Скорее пишите нам, будем рады сотрудничеству!</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>


</template>



<style scoped lang="scss">
  .row{
    display: flex;
  }

</style>
