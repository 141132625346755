<script setup>
import {contentService} from "@/services/content.service";
import {onMounted, ref} from "vue";
import BreadCrumbs from "@/components/block/BreadCrumbs";

const loadContent = async () => contentService.getSectionContent('pay')
const title = 'Доставка и оплата'
const content = ref('')

onMounted(async () => {
  content.value = await loadContent()
})

const outLinks = [
  {name: title, href:"pay"}
]

</script>

<template>
    <main class="main">

      <BreadCrumbs :out-links="outLinks" />
      <section class="delivery-and-payment section">
        <div class="container">
          <div class="section-header">
            <h2 class="section-header__title">{{content.name}}</h2>
          </div>
          <div class="delivery-and-payment-list">
            <div class="delivery-and-payment__item">
<!--              <h3 class="delivery-and-payment__item-title">Оплата</h3>-->
              <div class="delivery-and-payment__item-info">
                <div v-html="content.content" ></div>
<!--                <div class="delivery-and-payment__payment-methods">
                  <span>Способы оплаты</span>
                  <div class="payment-methods__list">
                    <img src="img/logos/visa.png" alt="VISA">
                    <img src="img/logos/mastercard.png" alt="Mastercard">
                    <img src="img/logos/mir.png" alt="МИР">
                  </div>
                </div>-->
              </div>
            </div>

          </div>
        </div>
      </section>
    </main>
</template>



<style scoped>

</style>
