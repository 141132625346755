<script setup>
import ProductList from "@/components/elements/ProductList";
import BreadCrumbs from "@/components/block/BreadCrumbs";
import { ref } from 'vue'

const outLinks = ref([])

function formBread(data)
{
  outLinks.value.push({
    name: data.value.name,
    href: data.value.href
  })
  if(outLinks.value.length >= 2){
    outLinks.value = outLinks.value.slice(1,2)
  }
}

</script>

<template>
  <main class="main">
  <BreadCrumbs :outLinks="outLinks" />
    <Suspense>
  <ProductList @section="formBread" />
    </Suspense>
  </main>
</template>



<style scoped>

</style>
