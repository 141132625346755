import {httpService} from "@/services/api/http.service";

const questionService = {
    async create(){

    },

    async get(id) {
      try {
        const result = await httpService.get('question/index?ofset=' + id);

        if (result.status === 200) {
          return result.data
        }
        return []
      }catch (e) {
          return []
      }
    }

}

export default questionService
