import axios from "axios";
import tokenService from "@/services/token.service";

export const httpService = {

    async get(href){
      const token = await tokenService.getToken()

      const result = await axios(process.env.VUE_APP_API_URL + '/api/' + href, {
        method: 'GET',
        headers: {
          'Origin': process.env.VUE_APP_ORIGIN,
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Methods': 'GET,OPTIONS,POST',
          'Access-Control-Allow-Headers': 'Accept, Content-Type, Authorization, Access-Control-Allow-Origin',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })

        return result;
    },

    async post(href, data){
      const token = await tokenService.getToken()

      const result = await axios(process.env.VUE_APP_API_URL + '/api/' + href, {
        method: 'POST',
        data: data,
        headers: {
          'Origin': process.env.VUE_APP_ORIGIN,
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Methods': 'GET,OPTIONS,POST',
          'Access-Control-Allow-Headers': 'Accept, Content-Type, Authorization, Access-Control-Allow-Origin',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      return result;
    }

}
