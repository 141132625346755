<script setup>
import {Swiper, SwiperSlide} from 'swiper/vue';
import {EffectFade, Navigation, Autoplay} from 'swiper'
import "swiper/css/effect-fade";
import {defineProps, onMounted, ref} from "vue";

import 'swiper/css';
import 'swiper/css/navigation';

const props = defineProps({
  firstPhoto: '',
  sliders: Array
})

const onSwiper = (swiper) => swiper;
const onSlideChange = () => console.log('slide change');
const modules = [EffectFade, Navigation, Autoplay];

function fullHref(name) {
  return process.env.VUE_APP_API_URL + '/images/catalog/' + name
}


</script>

<style>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>

<template>
  <swiper
      :autoHeight="true"
      :spaceBetween="20"
      :navigation="true"
      :pagination="{
      clickable: true,
    }"
      :autoplay="{
          delay:30000
        }"
      :modules="modules"
      :effect="'cube'"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      class="swiper">

    <swiper-slide class="swiper-slide">
      <img class="product__img" :src="fullHref(firstPhoto)" alt="{{firstPhoto}}">
    </swiper-slide>

    <swiper-slide
        v-for="slider in sliders"
        class="swiper-slide">
      <img class="product__img" :src="fullHref(slider.name)" alt="{{slider.name}}">
    </swiper-slide>
  </swiper>
</template>