<script setup>
import {helpService} from "@/services/help.service";
import {defineProps, defineEmits, onMounted, ref, watch, computed} from 'vue';
import { useRoute } from 'vue-router'

const route = useRoute()

const loadTags = async () => await helpService.getTagsSection(route.params.href);

defineProps({
  reset: {
    type: Boolean
  }
})

const emits = defineEmits([
    'hideTags', 'checkedTag'
])

const tags = ref([])
const checkedTag = ref([])

onMounted(async () => {
  tags.value = await loadTags()
  if (tags.value.length === 0) {
    hideBlock()
  }
})

function  hideBlock() {
  emits('hideTags', false)
}

function checkedTags()
{
    emits('checkedTag', checkedTag.value)
}


watch(() => route.path, async(newVal) => {
    tags.value = await loadTags()
    }
)
</script>

<template>
  <div class="tags-list">
    <div class="tags-list__item" v-for="itemTag in tags">
      <input
        :id="itemTag.id"
        :value="itemTag.id"
        type="checkbox"
        v-model="checkedTag"
        @change="checkedTags"
      >
      <label  :for="itemTag.id" class="tags-list__item-label">{{ itemTag.name }}</label>
    </div>

  </div>
</template>


<style scoped lang="scss">

</style>
