import axios from "axios";
import tokenService from "@/services/token.service";

const navService = {

  async basicBar() {
    const token = await tokenService.getToken();
    try{
        const result = await axios(process.env.VUE_APP_API_URL + '/api/site/basic-nav', {
          method: 'GET',
          headers: {
            'Origin': process.env.VUE_APP_ORIGIN,
            'User-Agent': ' curl/7.51.0',
            'Access-Control-Allow-Methods': 'GET,OPTIONS,POST',
            'Access-Control-Allow-Headers': 'Accept, Content-Type, Authorization, Access-Control-Allow-Origin',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        })

      if(result.status === 200){
        return result.data;
      }

    }catch (e){
      return e.message
    }
  },

  async otherBar() {
    const token = await tokenService.getToken();
    try{
      const result = await axios(process.env.VUE_APP_API_URL + '/api/site/other-nav', {
        method: 'GET',
        headers: {
          'Origin': process.env.VUE_APP_ORIGIN,
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Methods': 'GET,OPTIONS,POST',
          'Access-Control-Allow-Headers': 'Accept, Content-Type, Authorization, Access-Control-Allow-Origin',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })

      if(result.status === 200){
        return result.data;
      }

    }catch (e){
      return e.message
    }
  },

  async catalogBar() {
    const token = await tokenService.getToken();
    try{
      const result = await axios(process.env.VUE_APP_API_URL + '/api/site/catalog-nav', {
        method: 'GET',
        headers: {
          'Origin': process.env.VUE_APP_ORIGIN,
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Methods': 'GET,OPTIONS,POST',
          'Access-Control-Allow-Headers': 'Accept, Content-Type, Authorization, Access-Control-Allow-Origin',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })

      if(result.status === 200){
        return result.data;
      }

    }catch (e){
      return e.message
    }
  }
}

export default navService
