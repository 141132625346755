<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper'
import 'swiper/css/pagination';
import productService from "@/services/product.service";
import ProductModifyCard from "@/components/elements/ProductModifyCard";

export default {
  props: {
    condition: {
      type: Object
    }
  },
  name: "ProductSlider",
  components: {
    ProductModifyCard,
    Swiper,
    SwiperSlide,
  },
  setup () {
    const onSwiper = (swiper) => {

    };

    return {
      onSwiper,
      modules: [Navigation, Pagination]
    }
  },
  data() {
    return {
      product: [],
      swiperOptions: {
        breakpoints: {
          360: {
            slidesPerView: 1,
          },
          766: {
            slidesPerView: 2,
          },
          993: {
            slidesPerView: 4,
          }
        }
      }
    }
  },
  async mounted() {
    this.product = await productService.getSegment(this.condition)
  },
  methods: {
    fullHref(){
      return process.env.VUE_APP_API_URL + '/images/catalog/'
    }
  }
}
</script>

<template>

  <swiper
    :slides-per-view="4"
    :modules="modules"
    :navigation="false"
    :pagination="true"
    @swiper="onSwiper"
    :breakpoints="swiperOptions.breakpoints"
    class="products-grid_slider recommended-products-slider">
      <swiper-slide class="products-grid__item swiper-slide"
      v-for="item in product"
                    :key="item.id"
      >
      <ProductModifyCard :product="item" />
      </swiper-slide>
   <div class="swiper-pagination"></div>
  </swiper>
</template>


<style scoped lang="scss">

.swiper{
   .products-grid_slider{
     .swiper-pagination{
       display: flex;
     }
   }
}

.products-grid_slider .swiper-pagination {
    display: flex;
}

</style>
