import {defineStore} from 'pinia'

export const useOrderStore = defineStore('orderStore', {
    state: () => ({
        order: {
            typeDelivery: 0, // Тип доставки (самовывоз и т.д.)
            typePayment: 0 , //  Тип оплаты
            typeOwnership: 0, // Тип плательщика, добавить потом
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            organizationName: '',
            subscription: 0,
            deliveryCity: '',
            deliveryAddress: '',
            deliveryOffice: '',
            comment: '',
            transportCompany: '',
            promoCode: '',
            processingRules: 0,
        }
    }),
  persist: true,
  actions: {

  },
  getters: {

  }
})
