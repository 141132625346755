import { useBasketStore } from "@/stores/basketStore";

export const productHelper = {
    volume(item){
          /* product.attributesSets */
           let weight = item.find((el) => el.name === 'Вес')
          if(weight !== undefined){
            weight.value_int = weight.value_int.replace(/[,.]?0+$/, '')
            return weight.value_int
          }
          return undefined;
    },
  color(item){
    /* product.attributesSets */
    let color = item.find((el) => el.name === 'Цвет')
    color.value = color.value.trim()
    return color.value
  },
    expired(item){
      /* product.attributesSets */
      let weight = item.find((el) => el.name === 'Срок годности')
      if(weight !== undefined){
        return weight.value
      }
      return '----'
    },
    available(item){

    },
    checkedPrice(id, price){
      if(price !== undefined){
        return price.find((el) => price.id === id)
      }
      return {}
    },
    discountPrice(item)
    {
      const cart = useBasketStore()
       let blockPrice = {
          price: 0,
          basicPrice: 0,
          discount: 0,
          typeDiscount: ''
       }
       const volumeProduct = this.volume(item.attributesSets)
       if(item.available_akcii === 1){
          blockPrice.price = item.newprice
          blockPrice.basicPrice = item.price
          blockPrice.discount = Math.round(100 - (item.newprice * 100 / item.price))
          blockPrice.typeDiscount = 'акция'
       }
       else if ([5, 6].includes(item.section_id) && ['3.8', '1.9', '19'].includes(volumeProduct)  && cart.discount.gallon.amount >= 2){
         blockPrice.price = Math.round(item.price - (item.price / 100 * cart.discount.gallon.rate ))
         blockPrice.basicPrice = item.price
         blockPrice.discount = cart.discount.gallon.rate
         blockPrice.typeDiscount = ''
       }
       else{
         blockPrice.price = item.price
         blockPrice.basicPrice = item.price
         blockPrice.discount = 0
       }
       return blockPrice
    }

}
