import {httpService} from "@/services/api/http.service";

export const articleService = {
    async list(){
        const result = await httpService.get('article/index');

        if(result.status === 200){
            return result.data
        }
        return []
    },

  async get(id){
    const result = await httpService.get('article/view?id='+id);

    if(result.status === 200){
      return result.data
    }
    return []
  }
}
