import axios from "axios";

const authService = {

  async login(authData)
  {
    try {
      const {
        status, data
      } = await axios(process.env.VUE_APP_API_URL + '/api/site/login', {
        method: 'POST',
        data: authData,
        headers: {
          'Origin': process.env.VUE_APP_ORIGIN,
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Access-Control-Allow-Origin',
          'Bearer': null
        }
      })

      if(status === 200){
        return data
      }

    } catch (e) {
      return e.message
    }
  }
}

export default authService
