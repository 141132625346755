<script setup>
import BreadCrumbs from "@/components/block/BreadCrumbs";
import {computed, onMounted, ref} from 'vue';
import { useRoute } from "vue-router";
import {articleService} from "@/services/article.service";

const route = useRoute()

const loadArticle = async () => articleService.get(route.params.id)

const article = ref({})

onMounted(async () => {
  article.value = await loadArticle()
})

const title = computed(() => article.value.title )

const outLinks = [
  {
    name: 'Статьи', href: '/article'
  },
  {
      name: title, href: '1'
  }
]

function fullPath(img)
{
   return  process.env.VUE_APP_API_URL + '/images/article/' + img.name_img;
}

const check = computed(() => {
    if(article.value.dopImg != undefined && article.value.dopImg.length >= 1){
        return true;
    }
    return false;
});


</script>

<template>
  <main class="main">
    <BreadCrumbs :out-links="outLinks" />
    <section class="article section">
      <div class="container">
        <div class="article__header">
          <img v-if="article.img" class="article__header-img" :src="fullPath(article.img)" alt="">
          <h2 class="article__header-title">{{ article.title }}</h2>
        </div>
        <div class="article__content">
          <article>
            <div v-html="article.content"></div>
            <div class="swiper article-slider">

              <div class="swiper-wrapper" v-if="check">

                <img v-for="itemImg in article.dopImg" :key="itemImg.id" class="swiper-slide" :src="fullPath(itemImg)" alt="">
              </div>
<!--              <div class="swiper-button swiper-button_prev"></div>
              <div class="swiper-button swiper-button_next"></div>
              <div class="swiper-pagination"></div>-->
            </div>
          </article>


        </div>
      </div>
    </section>
<!--    <section class="products section">
      <div class="container">
        <div class="section-header">
          <h2 class="section-header__title">Новинки</h2>
          <div class="section-header__swiper-buttons novelties-products-slider">
            <div class="swiper-button swiper-button_prev"></div>
            <div class="swiper-button swiper-button_next"></div>
          </div>
        </div>
        <div class="products-grid_slider novelties-products-slider">
          <div class="swiper-wrapper">
            <div class="products-grid__item swiper-slide">
              <div class="products-grid__item-img-block">
                <img class="products-grid__item-img" src="img/products/product1.png" alt="Название товара">
                <span class="products-grid__item-status">акция</span>
              </div>
              <span class="products-grid__item-title"><span class="blue">Opawz.</span> Стойкая краска для шерсти животных перманентная</span>
              <div class="products-grid__item-bottom-and-details">
						<span class="products-grid__item-details">
							<img src="img/icons/colors.svg" alt="">
							15 цветов
						</span>
                <div class="products-grid__item-bottom">
                  <a class="products-grid__item-bottom-basket" href="">1000 ₽</a>
                  <span class="products-grid__item-bottom-other">скидка 15% <br> 1500₽</span>
                </div>
              </div>
            </div>
            <div class="products-grid__item swiper-slide">
              <div class="products-grid__item-img-block">
                <img class="products-grid__item-img" src="img/products/product2.png" alt="Название товара">
                <span class="products-grid__item-status">15%</span>
              </div>
              <span class="products-grid__item-title"><span class="blue">Davis.</span> Шампунь для очень загрязненной кожи</span>
              <div class="products-grid__item-bottom-and-details">
                <span class="products-grid__item-details">4 обьёма</span>
                <div class="products-grid__item-bottom">
                  <a class="products-grid__item-bottom-basket" href="">от 50 ₽</a>
                  <span class="products-grid__item-bottom-other">скидка 15% <br> <s>1500₽</s></span>
                </div>
              </div>
            </div>
            <div class="products-grid__item swiper-slide">
              <div class="products-grid__item-img-block">
                <img class="products-grid__item-img" src="img/products/product3.png" alt="Название товара">
                <span class="products-grid__item-status products-grid__item-status_green">2+1</span>
              </div>
              <span class="products-grid__item-title"><span class="blue">Эко-линия.</span> Древесный наполнитель, лиственный</span>
              <div class="products-grid__item-bottom-and-details">
                <span class="products-grid__item-details">4 обьёма</span>
                <div class="products-grid__item-bottom">
                  <a class="products-grid__item-bottom-basket" href="">от 50 ₽</a>
                  <span class="products-grid__item-bottom-other"><u><a
                    href="">все товары</a></u> <br> <s>1500₽</s></span>
                </div>
              </div>
            </div>
            <div class="products-grid__item swiper-slide">
              <div class="products-grid__item-img-block">
                <img class="products-grid__item-img" src="img/products/product1.png" alt="Название товара">
              </div>
              <span class="products-grid__item-title"><span class="blue">Opawz.</span> Стойкая краска для шерсти животных перманентная</span>
              <div class="products-grid__item-bottom-and-details">
						<span class="products-grid__item-details">
							<img src="img/icons/colors.svg" alt="">
							15 цветов
						</span>
                <div class="products-grid__item-bottom">
                  <a class="products-grid__item-bottom-basket" href="">1000 ₽</a>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </section>-->
  </main>
</template>

<style scoped lang="scss">

</style>
