<script setup>
import {computed, defineProps, ref, toRefs} from 'vue'

const props = defineProps({
    outLinks: Array
})

const { outLinks } = toRefs(props)

const links = ref([
  {
    name: 'Главная',
    href: '/'
  }
])

const viewBreadcrumbs = computed(() => {
  if (outLinks.value.length >= 1) {

    links.value.push(...outLinks.value)
    return links.value
  }
  return links.value
})

</script>

<template>
  <section class="bread-crumbs section">
    <div class="container">
      <div class="bread-crumbs-list">
         <router-link
           v-for="(item, key) in viewBreadcrumbs"
           :key="key"
           class="bread-crumbs__link"
                      :to="item.href">
           {{ item.name }}
         </router-link>
      </div>
    </div>
  </section>
</template>


<style scoped lang="scss">

</style>
