import {defineStore} from "pinia";

export const useSearchStore = defineStore('searchStore', {
  state: () => ({
    query: ''
  }),
  persist: true,
  actions:{
      saveQuery(value)
      {
          this.query = value
      }
  },
  getters: {

  }
})
