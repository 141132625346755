import {defineStore} from 'pinia'
import {productHelper} from "@/components/helpers/product.helper";

export const useBasketStore = defineStore('basketStore', {
  state: () => ({
    cart: [],
    discount: {
      gallon: {
        amount: 0,
        rate: 0
      },
      sum: 0
    }
  }),
  persist: true,
  actions: {
    async addCart(item, count) {
      if (count === undefined) {
        count = 1
      }
      const key = this.cart.findIndex(el => el.id === item.id)
      if (key >= 0) {
        this.cart[key].count += count
      } else {
        item.count = count

        this.cart.push(item)
      this.calculationDiscount()

      }
    },
    async clearCart() {
      this.cart = []
      this.calculationDiscount()
    },
    async clearDiscount() {
        this.discount.gallon.rate = 0;
        this.discount.gallon.amount = 0;
    },
    async modifyCount(item, count) {
      const key = this.cart.findIndex(el => el.id === item.id)

      this.cart[key].count = count
      this.calculationDiscount()
    },
    async delete(item) {
      this.cart = this.cart.filter(el => el.id !== item.id)
      this.calculationDiscount()
    },
    calculationDiscount() {
      let goodsCount = this.cart.reduce((count, item) => {
        let volume = productHelper.volume(item.attributesSets)
        if ([5, 6].includes(item.section_id) && (['3.8', '1.9', '19'].includes(volume))  && item.available_akcii === 0) {
          return count += item.count
        } else {
          return count
        }
      }, 0)
      this.discount.gallon.amount = goodsCount
      if (goodsCount >= 2 &&  goodsCount <= 3)
      {
        this.discount.gallon.rate = 4;
      }
      else if(goodsCount === 4)
      {
        this.discount.gallon.rate = 6;
      }
      else if(goodsCount >= 5)
      {
        this.discount.gallon.rate = 8;
      }
      else
      {
        this.discount.gallon.rate = 0;
      }
    },
    getPrice(p){
        if(p.available_akcii === 1){
            return p.newprice
        }
        if(this.discount.gallon.rate > 0 && this.checkProduct(p)){
          return p.price - (p.price / 100 * this.discount.gallon.rate)
        }
        return p.price
    },
    checkProduct(item)
    {
      let volume = productHelper.volume(item.attributesSets)
        if([5, 6].includes(item.section_id) && (['3.8', '1.9', '19'].includes(volume))  && item.available_akcii === 0){
            return true
        }
        return false
    }
  },
  getters: {
    countCartItems() {
      return this.cart.reduce((a, b) => a + b.count, 0)
    },
    cartItems() {
      return this.cart
    },
    totalAmount() {
      return this.cart.reduce((total, p) => {
        let price = this.getPrice(p)
        return total + price * p.count
      }, 0)
    }
  }
})
