<script setup>
import {defineProps, ref} from 'vue'

defineProps({
    news: {
      type: Object
    }
})

const emits = defineEmits([
  'readId'
])

function toggle()
{
    emits('readId', true)
}

function fullPath(name){
  return process.env.VUE_APP_API_URL + '/images/news/' + name
}

</script>

<template>
  <div class="faq-list__item-header" @click="toggle">
    <span class="faq-list__item-tag">{{ news.created_at }}</span>
    <p class="faq-list__item-title">{{ news.headline }}</p>
  </div>
  <div class="faq-list__item-body">
    <p v-html="news.content"></p>
    <p class="image_news"><img :src="fullPath(img.name)"
            v-for="img in news.image" :key="img.id"
        height="250"
    ></p>
  </div>
</template>



<style scoped lang="scss">
  .image_news {
      display: flex;
      flex-wrap: wrap;
      img{
        margin: 5px;
      }
  }
</style>
