<script setup>
import ProductCard from "@/components/elements/ProductCard";
import {useSearchStore} from "@/stores/searchStore";
import productService from "@/services/product.service";
import BreadCrumbs from "@/components/block/BreadCrumbs";
import {computed, onMounted, ref, watch} from 'vue'

const searchStore = useSearchStore()

const product = ref([])
const section = ref({})

const viewProduct = computed(() => {
    return product.value
})

const loadSearch = async () => {
  return await productService.getSearch(searchStore.query)
}

onMounted(async () => {
    product.value = await loadSearch()
})

watch(searchStore, async(newQuery, oldQuery) => {
  product.value = await loadSearch()
})

</script>

<template>
  <main class="main">
   <BreadCrumbs :outLinks="[{name:'Поиск по сайту', href: '/search'}]"/>
    <section class="products section">
      <div class="container">
        <div class="section-header">
          <h2 class="section-header__title">Результаты поиска: "{{ searchStore.query }}"</h2>
        </div>
      <div class="products-grid" v-show="viewProduct.length >= 1">
          <div class="products-grid__item" v-for="item in viewProduct">
            <Suspense>
               <ProductCard :product="item" :sectionProduct="section"/>
            </Suspense>
          </div>
        </div>
     <h3 v-show="viewProduct.length === 0">По вашему запросу товаров ничего не найдено</h3>
      </div>
    </section>


  </main>
</template>



<style scoped>

</style>
