import {httpService} from "@/services/api/http.service";

export const dealerService = {

      async getList()
      {
          const result = await httpService.get('dealer/index')

          if(result.status === 200){
              return result.data
          }
          return []
      }
}
