<script>

export default {
  props: {
    dataArgument: {
      type: Object,
    }
  },
  name: "NewsBlock",
  data() {
    return {
      item: [],
    }
  },
  async mounted() {
    this.item = this.dataArgument
  },
}

</script>

<template>
  <div class="ras2-list__item swiper-slide-active" style="width: 290px;">
    <span class="ras2-list__item-date">{{ item.created_at }}</span>
    <p class="ras2-list__item-title"> {{ item.headline }}</p>
    <img class="ras2-list__item-arrow-right-icon" src="/img/icons/arrow-right-big-black.svg" alt="">
  </div>
</template>

<style scoped>

</style>