import {httpService} from "@/services/api/http.service";
import tokenService from "@/services/token.service";

export const contentService = {

      async getSectionContent(href) {
          try {
            const token = await tokenService.getToken()

            const result = await httpService.post('content/document', {href: href})

            return result.data

          } catch (e){
            return e.message
          }
      }
}
