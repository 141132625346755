<script setup>

import BreadCrumbs from "@/components/block/BreadCrumbs";
import {useOrderStore} from "@/stores/orderStore";
import {useVuelidate} from "@vuelidate/core";
import {required, requiredIf, email, minLength, helpers, numeric} from '@vuelidate/validators'
import {orderService} from "@/services/api/order.service";
import BeforeOrder from "@/components/global/BeforeOrder";
import {ref, computed} from 'vue'
import {useBasketStore} from "@/stores/basketStore";
import beforeOrder from "@/components/global/BeforeOrder.vue";

const outLinks = [
  {
    name: 'Корзина товаров',
    href: '/cart'
  },
  {
    name: 'Оформление заказа',
    href: '/checkout'
  }
]

const order = useOrderStore()
const cart = useBasketStore()

const validations = computed(() => {
  const localRules = {
    lastName: {
      required: helpers.withMessage('Обязательно к заполнению', required),
      minLength: helpers.withMessage('От трех знаков', minLength(3))
    },
    firstName: {
      required: helpers.withMessage('Обязательно к заполнению', required),
      minLength: helpers.withMessage('От трех знаков', minLength(3))
    },
    typeOwnership: {
      required: helpers.withMessage('Обязательно к заполнению', required),
    },
    phone: {
      required: helpers.withMessage('Обязательно к заполнению', required),
    },
    email: {
      required: helpers.withMessage('Обязательно к заполнению', required),
      email: helpers.withMessage('Неправильный формат', email)
    },
    deliveryCity: {
      requiredIf: helpers.withMessage('Обязательно к заполнению', requiredIf(order.order.typeDelivery != 1)),
    },
    deliveryAddress: {
      requiredIf: helpers.withMessage('Обязательно к заполнению', requiredIf(order.order.typeDelivery != 1)),
    },
    deliveryOffice: {
      requiredIf: helpers.withMessage('Обязательно к заполнению', requiredIf(order.order.typeDelivery != 1)),
    }

  }

  return localRules
})


const v$ = useVuelidate(validations, order.order)

const showThanks = ref(false);

async function sendOrder() {
  const isFormCorrect = await v$.value.$validate()

  if (!isFormCorrect) return

  let result = await orderService.create()

  if (result) {
    await cart.clearCart()
    await cart.clearDiscount()
    showThanks.value = true;
  }
}

</script>

<template>
  <main class="main">
    <BreadCrumbs :out-links="outLinks"/>
    <section class="checkout section">
      <div class="container">
        <div class="section-header section-header_flex-direction-column">
          <h2 class="section-header__title">Оформление заказа</h2>
        </div>
        <div class="checkout__inner">
          <div class="checkout__top">
            <div class="checkout__control">
              <div class="form__radio">
                <input type="radio" id="individual" name="organization" value="1" v-model="order.order.typeOwnership">
                <label class="form__radio-custom" for="individual"></label>
                <label class="form__radio-label" for="individual">Физическое лицо</label>
              </div>
            </div>
            <div class="checkout__control">
              <div class="form__radio">
                <input type="radio" id="entity" name="organization" value="2" v-model="order.order.typeOwnership">
                <label class="form__radio-custom" for="entity"></label>
                <label class="form__radio-label" for="entity">Юридическое лицо</label>
              </div>
            </div>
            <span v-if="v$.typeOwnership.required.$invalid">{{ v$.typeOwnership.required.$message }}</span>
          </div>
          <div class="checkout__list">
            <section class="checkout__item">
              <div class="checkout__item-body">
                <h3 class="checkout__item-title">Получатель</h3>
                <div class="form__controls-group form__controls-group_col-3">
                  <div class="form__control">
                    <label class="form__control-label">Фамилия</label>
                    <input v-model="order.order.lastName"
                           class="form__control-input" type="text" name="last-name" placeholder="Столыпин"
                           :class="{'form-error':  (v$.lastName.$dirty && v$.lastName.$invalid)}">
                    <span v-if="v$.lastName.required.$invalid">{{ v$.lastName.required.$message }}</span>
                  </div>
                  <div class="form__control">
                    <label class="form__control-label">Имя</label>
                    <input v-model="order.order.firstName"
                           class="form__control-input" type="text" name="first-name" placeholder="Виктор"
                           :class="{'form-error':  (v$.firstName.$dirty && v$.firstName.$invalid)}">
                    <span v-if="v$.firstName.required.$invalid">{{ v$.firstName.required.$message }}</span>
                  </div>
                  <div class="form__control">
                    <label class="form__control-label">Телефон</label>
                    <input v-model="order.order.phone"
                           class="form__control-input" type="text" name="phone" placeholder="+7 (968) 268-96-32"
                           :class="{'form-error':  (v$.phone.$dirty && v$.phone.$invalid)}">
                    <span v-if="v$.phone.required.$invalid">{{ v$.phone.required.$message }}</span>
                  </div>
                  <div class="form__control">
                    <label class="form__control-label">E-mail</label>
                    <input v-model="order.order.email"
                           class="form__control-input" type="email" name="email" placeholder="Stopigin@lyly.ru"
                           :class="{'form-error':  (v$.email.$dirty && v$.email.$invalid)}">
                    <span v-if="v$.email.required.$invalid">{{ v$.email.required.$message }}</span>
                  </div>
                </div>
                <div class="form__checkbox">
                  <input v-model="order.order.subscription" type="checkbox" id="receive-news" name="organization" checked>
                  <label class="form__checkbox-custom" for="receive-news"></label>
                  <label class="form__checkbox-label" for="receive-news">Я хочу получать рекламные сообщения и новости о товарах и акциях</label>
                </div>
              </div>
            </section>
            <section class="checkout__item" style="display: flex">
              <div class="checkout__control">
                <div class="form__radio">
                  <input type="radio" id="pickup" name="delivery" value="1" v-model="order.order.typeDelivery">
                  <label class="form__radio-custom" for="pickup"></label>
                  <label class="form__radio-label" for="pickup">Самовывоз</label>
                </div>
              </div>
              <div class="checkout__control">
                <div class="form__radio">
                  <input type="radio" id="courier" name="delivery" value="3" v-model="order.order.typeDelivery">
                  <label class="form__radio-custom" for="courier"></label>
                  <label class="form__radio-label" for="courier">Доставка</label>
                </div>
              </div>
            </section>
            <section class="checkout__item">
              <div class="checkout__item-body" v-if="order.order.typeDelivery==3">
                <h3 class="checkout__item-title">Адрес доставки</h3>
                <div class="form__controls-group form__controls-group_col-2_col-1">
                  <div class="form__control">
                    <label class="form__control-label">Населённый пункт</label>
                    <input v-model="order.order.deliveryCity"
                           class="form__control-input" type="text"
                           :class="{'form-error':  (v$.deliveryCity.$dirty && v$.deliveryCity.$invalid)}"
                           placeholder="Москва" required>
                    <span v-if="v$.deliveryCity.requiredIf.$invalid">{{ v$.deliveryCity.requiredIf.$message }}</span>
                  </div>
                  <div class="form__control">
                    <label class="form__control-label">Кв. или офис</label>
                    <input v-model="order.order.deliveryOffice"
                           class="form__control-input"
                           :class="{'form-error':  (v$.deliveryOffice.$dirty && v$.deliveryOffice.$invalid)}"
                           type="text" name="" placeholder="85" required>
                    <span v-if="v$.deliveryOffice.requiredIf.$invalid">{{ v$.deliveryOffice.requiredIf.$message }}</span>
                  </div>
                  <div class="form__control">
                    <label class="form__control-label">Улица и дом</label>
                    <input v-model="order.order.deliveryAddress"
                           class="form__control-input"
                           :class="{'form-error':  (v$.deliveryAddress.$dirty && v$.deliveryAddress.$invalid)}"
                           type="text" name="" placeholder="ул. Зеленая, д.15" required>
                    <span v-if="v$.deliveryAddress.requiredIf.$invalid">{{ v$.deliveryAddress.requiredIf.$message }}</span>
                  </div>
                  <div class="form__control form__control__col-span-2">
                    <label class="form__control-label">Комментарий к доставке</label>
                    <textarea v-model="order.order.comment"
                              class="form__control-textarea" name="" placeholder="" required></textarea>
                  </div>
                </div>
              </div>
              <div class="checkout__item-body" v-if="order.order.typeDelivery==1">
                Забрать на складе по адресу: г. Москва, Южнобутовская 29
              </div>
            </section>
            <section class="checkout__item checkout__item_delivery-type" v-if="order.order.typeDelivery==3">
              <div class="checkout__item-body">
                <h3 class="checkout__item-title">Транспортная компания</h3>
                <div class="checkout__item-body-wrap">

                  <div class="form__controls-group_radio-img">
                    <div class="form__radio_img">
                      <input type="radio" id="gto" name="delivery-type" checked>
                      <label class="form__radio_img-custom" for="gto">
                        <img src="img/logos/gto.png" alt="GTO">
                      </label>
                    </div>
                    <div class="form__radio_img">
                      <input type="radio" id="major" name="delivery-type">
                      <label class="form__radio_img-custom" for="major">
                        <img src="img/logos/major.png" alt="Major">
                      </label>
                    </div>
                    <div class="form__radio_img">
                      <input type="radio" id="SDEK" name="delivery-type">
                      <label class="form__radio_img-custom" for="SDEK">
                        <img src="img/logos/sdek.png" alt="СДЭК">
                      </label>
                    </div>
                    <div class="form__radio_img">
                      <input type="radio" id="pochta-rossii" name="delivery-type">
                      <label class="form__radio_img-custom" for="pochta-rossii">
                        <img src="img/logos/pochta-rossii.png" alt="Почта России">
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="checkout__item checkout__item_payment-method">
              <div class="checkout__item-body">
                <h3 class="checkout__item-title">Способ оплаты</h3>
                <div class="form__controls-group_radio-img">
                  <div class="form__radio_img">
                    <input type="radio" id="bank-card" name="payment-method" value="2" checked v-model="order.order.typePayment">
                    <label class="form__radio_img-custom" for="bank-card">
                      <img src="img/logos/card-payment.png" alt="Оплата банковской картой">
                    </label>
                  </div>
                  <div class="form__radio_img">
                    <input type="radio" id="sbp" name="payment-method" value="3" v-model="order.order.typePayment">
                    <label class="form__radio_img-custom" for="sbp">
                      <img src="img/logos/cashless.png" alt="Оплата по безналичному расчету">
                    </label>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div class="checkout__bottom">
            <div class="checkout__bottom-btn-block">
              <button @click="sendOrder" class="btn btn_blue">Оформить заказ</button>
              <p class="description">Нажимая кнопку, я даю
                <a href="">согласие</a> на обработку персональных данных, в соответствии с
                <a href="">Политикой</a>, и соглашаюсь с <a href="">Правилами</a></p>
            </div>
            <div class="checkout__bottom-info">
              <ul class="checkout__bottom-info-details">
                <li>
                  <span>Товары {{ cart.countCartItems }}</span>
                  <span>{{ cart.totalAmount }} ₽</span>
                </li>
              </ul>
              <div class="checkout__bottom-info-total">
                <span class="text">Итого</span>
                <span class="price">{{ cart.totalAmount }} ₽</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <BeforeOrder :show="showThanks"/>
  </main>
</template>


<style scoped lang="scss">
.form-error {
  border: 1px red solid;
}

.checkout__item_delivery-type .form__controls-group_radio-img {
  display: flex;
}
</style>
