<script setup>
import { defineProps } from 'vue'
import {clearHelper} from "@/components/helpers/clear.helper";
import { useRoute } from "vue-router";

defineProps({
    article: {
      type: Object
    }
})

const route = useRoute()

function splitText(text)
{
    return clearHelper.split(clearHelper.html(text))
}

function fullPath(img)
{
  if(img != null){
    return process.env.VUE_APP_API_URL + '/images/article/' + img.name_img;
  }
  return null;
}


</script>

<template>

  <span class="articles-list__item-author">{{ article.author }}</span>
  <p class="articles-list__item-title"> {{ article.title }}</p>
  <p class="articles-list__item-text" v-html="splitText(article.content)"></p>
  <div class="articles-list__item-btn-and-img">
  <router-link class="articles-list__item-btn" :to="{ name: 'articleBlock', params:{ id: article.id }  }">Читать полностью</router-link>

    <img class="articles-list__item-img" :src="fullPath(article.img)" width="228" height="190" alt="">
  </div>
</template>



<style scoped>

</style>
