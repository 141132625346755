<template>
    <main class="main">

      <section class="bread-crumbs section">
        <div class="container">
          <div class="bread-crumbs-list">
            <a class="bread-crumbs__link" href="">Шампуни</a>
            <a class="bread-crumbs__link" href="">Заключительный этап стрижки</a>
            <a class="bread-crumbs__link" href="">Davis</a>
          </div>
        </div>
      </section>
      <section class="sales section">
        <div class="container">
          <div class="section-header section-header_low-bottom-margin">
            <h2 class="section-header__title">Акции - скидки на корма для животных и зоотовары</h2>
          </div>

          <div class="tags tags_low-bottom-margin">
            <div class="tags__info">
              <span class="tags__info-label">Часто ищут:</span>
              <span class="tags__info-clear">очистить</span>
            </div>
            <div class="tags-list">
              <div class="tags-list__item">
                <input id="Обезжиривание" type="checkbox">
                <label for="Обезжиривание" class="tags-list__item-label">Обезжиривание</label>
              </div>
              <div class="tags-list__item">
                <input id="Усиление цвета" type="checkbox">
                <label for="Усиление цвета" class="tags-list__item-label">Усиление цвета</label>
              </div>
              <div class="tags-list__item">
                <input id="Первая чистка" type="checkbox">
                <label for="Первая чистка" class="tags-list__item-label">Первая чистка</label>
              </div>
              <div class="tags-list__item">
                <input id="Для собак" type="checkbox">
                <label for="Для собак" class="tags-list__item-label">Для собак</label>
              </div>
              <div class="tags-list__item">
                <input id="Увлажнение" type="checkbox" checked>
                <label for="Увлажнение" class="tags-list__item-label">Увлажнение</label>
              </div>
              <div class="tags-list__item">
                <input id="Для быстрой сушки" type="checkbox">
                <label for="Для быстрой сушки" class="tags-list__item-label">Для быстрой сушки</label>
              </div>
              <div class="tags-list__item">
                <input id="Обезжиривание2" type="checkbox">
                <label for="Обезжиривание2" class="tags-list__item-label">Обезжиривание</label>
              </div>
              <div class="tags-list__item">
                <input id="Усиление цвета2" type="checkbox">
                <label for="Усиление цвета2" class="tags-list__item-label">Усиление цвета</label>
              </div>
              <div class="tags-list__item">
                <input id="Первая чистка2" type="checkbox">
                <label for="Первая чистка2" class="tags-list__item-label">Первая чистка</label>
              </div>
              <div class="tags-list__item">
                <input id="Для собак2" type="checkbox">
                <label for="Для собак2" class="tags-list__item-label">Для собак</label>
              </div>
              <div class="tags-list__item">
                <input id="Увлажнение2" type="checkbox">
                <label for="Увлажнение2" class="tags-list__item-label">Увлажнение</label>
              </div>
              <div class="tags-list__item">
                <input id="Для быстрой сушки2" type="checkbox">
                <label for="Для быстрой сушки2" class="tags-list__item-label">Для быстрой сушки</label>
              </div>
            </div>
          </div>
          <div class="sales-list">
            <div class="sales-list__item">
              <img class="sales-list__item-img" src="img/slider/slide1.png" alt="">
              <div class="sales-list__item-info">
                <span class="sales-list__item-status sales-list__item-status_green">2+1</span>
                <p class="sales-list__item-title">При покупке двух шампуней Davis 5 литров третий со скидкой 50% При покупке двух шампуней Davis 5 литров</p>
                <span class="sales-list__item-date">с 1 декабря 2022 по 1 марта 2023</span>
              </div>
            </div>
            <div class="sales-list__item">
              <img class="sales-list__item-img" src="img/slider/slide1.png" alt="">
              <div class="sales-list__item-info">
                <span class="sales-list__item-status sales-list__item-status_green">2+1</span>
                <p class="sales-list__item-title">При покупке двух шампуней Davis 5 литров третий со скидкой 50%</p>
                <span class="sales-list__item-date">с 1 декабря 2022 по 1 марта 2023</span>
              </div>
            </div>
            <div class="sales-list__item">
              <img class="sales-list__item-img" src="img/slider/slide1.png" alt="">
              <div class="sales-list__item-info">
                <span class="sales-list__item-status sales-list__item-status_green">2+1</span>
                <p class="sales-list__item-title">При покупке двух шампуней Davis 5 литров третий со скидкой 50%</p>
                <span class="sales-list__item-date">с 1 декабря 2022 по 1 марта 2023</span>
              </div>
            </div>
            <div class="sales-list__item">
              <img class="sales-list__item-img" src="img/slider/slide1.png" alt="">
              <div class="sales-list__item-info">
                <span class="sales-list__item-status sales-list__item-status_green">2+1</span>
                <p class="sales-list__item-title">При покупке двух шампуней Davis 5 литров третий со скидкой 50%</p>
                <span class="sales-list__item-date">с 1 декабря 2022 по 1 марта 2023</span>
              </div>
            </div>
            <div class="sales-list__item">
              <img class="sales-list__item-img" src="img/slider/slide1.png" alt="">
              <div class="sales-list__item-info">
                <span class="sales-list__item-status sales-list__item-status_green">2+1</span>
                <p class="sales-list__item-title">При покупке двух шампуней Davis 5 литров третий со скидкой 50%</p>
                <span class="sales-list__item-date">с 1 декабря 2022 по 1 марта 2023</span>
              </div>
            </div>
            <div class="sales-list__item">
              <img class="sales-list__item-img" src="img/slider/slide1.png" alt="">
              <div class="sales-list__item-info">
                <span class="sales-list__item-status sales-list__item-status_green">2+1</span>
                <p class="sales-list__item-title">При покупке двух шампуней Davis 5 литров третий со скидкой 50%</p>
                <span class="sales-list__item-date">с 1 декабря 2022 по 1 марта 2023</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="pagination">
        <a class="pagination__item pagination__item_prev" href="">Назад</a>
        <a class="pagination__item active" href="">1</a>
        <a class="pagination__item" href="">2</a>
        <a class="pagination__item" href="">3</a>
        <a class="pagination__item pagination__item_next" href="">Вперед</a>
      </div>		</main>

</template>

<script>
import Header from "@/layouts/Header";
import Footer from "@/layouts/Footer";

export default {
  name: "Akcii",
  components: {Header, Footer}
}
</script>

<style scoped>

</style>
