<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  image: Object
})

function  fullPatch()
{
  return process.env.VUE_APP_API_URL + '/images/catalog/'
}
</script>

<template>
  <router-link v-if="image.href"  :to="{name: 'product', params: { href: image.href, id: image.id }}" exact>
  <img
    :class="image.class"
       :width="image.width"
       :height="image.height"
       :src="fullPatch() + image.name"
       :alt="image.alt">
  </router-link>
  <img v-else
    :class="image.class"
    :width="image.width"
    :height="image.height"
    :src="fullPatch() + image.name"
    :alt="image.alt">
</template>



<style scoped>

</style>
