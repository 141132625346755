<script setup>
import {computed, onMounted, ref} from 'vue'
import BreadCrumbs from "@/components/block/BreadCrumbs";
import productService from "@/services/product.service";
import {useRoute, useRouter} from 'vue-router'
import {productHelper} from "@/components/helpers/product.helper";
import {useBasketStore} from "@/stores/basketStore";
import {createToaster} from "@meforma/vue-toaster";
import SliderProduct from "@/components/elements/SliderProduct.vue";

const route = useRoute()
const router = useRouter()
const cart = useBasketStore()
const toaster = createToaster({ /* options */});
const outLinks = ref([])


const loadProduct = async () => await productService.getOneProduct(route.params.href)
const product = ref({})
const checkedProduct = ref('')
const countProduct = ref(1)


const volume = (item) => productHelper.volume(item)
const color = (item) => productHelper.color(item)
const expired = (item) => productHelper.expired(item)

onMounted(async () => {
  let q = await loadProduct()
  if (q === null) {
    router.push({
      name: 'error'
    })
  }
  product.value = q

  if (q.property.length !== 0) {
    blockProperty.value = q.property[0].id;
  }

  outLinks.value = [{
    name: product.value.section.name,
    href: '/catalog/' + product.value.section.href
  }, {
    name: product.value.name,
    href: product.value.href
  }]
})

function fullHref(name) {
  return process.env.VUE_APP_API_URL + '/images/catalog/' + name
}

const detail = ref('')
const currentPrice = ref({
  price: 0,
  basicPrice: 0,
  discount: 0
})

function changeProduct(id) {
  checkedProduct.value = id
  detail.value = product.value.price.find((item) => item.id === id)
  currentPrice.value = productHelper.discountPrice(detail.value)
}

function buyProduct() {
  if (detail.value === '') {
    toaster.error(`Необходимо выбрать вариант фасовки`);
    return
  }
  cart.addCart(detail.value, countProduct.value)
  toaster.success(`Товар добавлен в корзину`);
}

const blockProperty = ref(0)

function toggleBlock(property, id) {
  if (property !== id) {
    blockProperty.value = id
  } else {
    blockProperty.value = 0
  }
}

function setsId(item) {
  const str = item.attributesSets.map(item => item.value !== null ? item.value : item.value_int)

  return str.join(' ');

}

</script>

<template>
  <main class="main">
    <BreadCrumbs :outLinks="outLinks"/>

    <section class="product section">
      <div class="container">
        <div class="section-header section-header_flex-direction-column">
          <h2 class="section-header__title"><span class="blue">{{ product.vendor_name }}</span> {{ product.name }}</h2>
          <p class="section-header__subtitle"> {{ product.import_name }} </p>
        </div>

        <div class="product__inner">
          <div class="product__img-block" style="display: grid;">
            <SliderProduct :first-photo="product.img" :sliders="product.otherImage"/>
            <span class="product__status"><i v-if="currentPrice.discount != 0">{{ currentPrice.discount }}</i> %</span>
          </div>

          <div class="product__info-block">
            <div class="product__block-controls">
              <p class="product__block-controls-title">Варианты фасовки</p>
              <div class="product__group-controls" v-if="product.price">
                <div class="form__radio_outline" v-for="item in product.price" :key="item.id">
                  <input
                      type="radio"
                      :id="item.id"
                      name="volume"
                      v-model="checkedProduct"
                      :checked="(checkedProduct === item.id)"
                  >
                  <label @click="changeProduct(item.id)"
                         class="form__radio_outline-custom"
                         :for="item.id">{{ setsId(item) }}
                  </label>
                  <label :for="item.id">{{ item.price }} ₽</label>
                  <label for="discount" v-if="item.available_akcii === 1"> - {{ productHelper.discountPrice(item).discount }}%</label>
                </div>
              </div>
            </div>
            <div class="product__delimiter"></div>
            <div class="product__details" v-if="detail">

              <div class="product__details-col product__details-col_key">Вес</div>
              <div class="product__details-col product__details-col_value">{{ volume(detail.attributesSets) + detail.unit }}</div>
              <div class="product__details-col product__details-col_key">Срок годности</div>
              <div class="product__details-col product__details-col_value">{{ expired(detail.attributesSets) }}</div>
              <div class="product__details-col product__details-col_key">Наличие</div>
              <div class="product__details-col product__details-col_value" v-if="detail.available === 1">В наличии</div>
              <div class="product__details-col product__details-col_value" v-else>Ожидаем поставку</div>
            </div>
            <p class="product__description" v-html="product.small_content"></p>
            <div class="product__btns">
              <div class="product__btn-count-group">
                <button class="count-btn count-decrease" @click="countProduct--">-</button>
                <!--								<span class="count">1</span>-->
                <input class="count" type="text" v-model="countProduct">
                <button class="count-btn count-increase" @click="countProduct++">+</button>
              </div>
              <a @click="buyProduct" class="product__btn-basket" href="javascript:void(0)"> {{ currentPrice.price }} ₽</a>
              <span v-if="currentPrice.discount !== 0" class="product__other">скидка {{ currentPrice.discount }}%
               <br> <s>{{ currentPrice.basicPrice }}₽</s>
             </span>
            </div>
          </div>

          <div class="product__tabs tabs">
            <div v-if="product.property != 0" class="tabs__controls">
              <span class="tabs__controls-tab" :class="{'tabs__controls-tab_active': blockProperty === property.id}" v-for="(property, index) in product.property" :key="property.id" @click="toggleBlock(blockProperty, property.id)">
                {{ property.name }}
              </span>
            </div>
            <div class="tabs__controls" v-else>
              <span class="tabs__controls-tab tabs__controls-tab_active">Описание</span>
            </div>

            <div v-if="product.property != 0" class="tabs__body">
              <div v-for="itemP in product.property" :key="itemP.id"
                   class="tabs__content"
                   :class="{'tabs__content_active': itemP.id === blockProperty}">

                <div class="tabs__content-group">
                  <div class="tabs__content-group-body" v-html="itemP.text">
                  </div>
                </div>
              </div>
              <div class="tabs__content">
                <div class="tabs__content-group">
                  <p class="tabs__content-group-title">Меры предосторожности</p>
                  <ul class="tabs__content-group-ul-with-icons">
                    <li>
                      <svg width="30" height="30">
                        <use href="img/icons.svg#warning-circle" fill="#2ea6e5"></use>
                      </svg>
                      Используйте защитный воротник
                    </li>
                    <li>
                      <svg width="30" height="30">
                        <use href="img/icons.svg#file-pdf" fill="#2ea6e5"></use>
                      </svg>
                      Обязательно ознакомьтесь с инструкцией
                    </li>
                  </ul>
                </div>
                <div class="tabs__content-group">
                  <p class="tabs__content-group-title">Способ применения</p>
                  <div class="tabs__content-group-body">
                    <p>Тщательно намочите шерсть теплой водой, нанесите шампунь на голову, уши и вспеньте.</p>
                    <p>Избегайте попадания в глаза и уши. Дайте шампуни впитаться на протяжении 10 минут. Тщательно
                      смойте водой. Повторяйте процедуру дважды в неделю пока симптомы не спадут, затем 1 раз в неделю
                      или в соответствии с указаниями ветеринара. При желании можете использовать шампунь с
                      Хлоргексидином после шампуня с Миконазолом или создайте свою смесь.</p>
                    <p>Избегайте попадания в глаза и слизистые оболочки. При попадании немедленно смойте водой. При
                      появлении раздражения на коже прекратить использование и проконсультироваться с ветеринаром.</p>
                  </div>
                </div>
              </div>
              <div class="tabs__content">

              </div>
              <div class="tabs__content">
                <div class="faq-list faq-list_dropdown">
                  <div class="faq-list__item">
                    <div class="faq-list__item-header">
                      <span class="faq-list__item-tag">Акция</span>
                      <p class="faq-list__item-title">Акция «Вместе дешевле» на Davis Manes&More шампунь и кондиционер
                        для собак и лошадей</p>
                    </div>
                    <div class="faq-list__item-body">
                      <p>Как принято считать, независимые государства набирают популярность среди определенных слоев
                        населения, а значит, должны быть объявлены нарушающими общечеловеческие нормы этики и морали.
                        Безусловно, убеждённость некоторых оппонентов требует определения и уточнения своевременного
                        выполнения сверхзадачи. Идейные соображения высшего порядка, а также убеждённость некоторых
                        оппонентов предоставляет широкие возможности для модели развития. В рамках спецификации
                        современных стандартов, диаграммы связей неоднозначны и будут разоблачены.</p>
                      <a class="faq-list__item-link" href="">Перейти к Акции</a>
                    </div>
                  </div>
                  <div class="faq-list__item">
                    <div class="faq-list__item-header">
                      <span class="faq-list__item-date">18 ноября 2022</span>
                      <p class="faq-list__item-title">Участие в Национальной Ветеринарной Конференции</p>
                    </div>
                    <div class="faq-list__item-body">
                      <div class="faq-list__item-body-info-and-media">
                        <div class="faq-list__item-body-info">
                          <p>Амигос, привет! 18 ноября 2022 года,побывали на СЕМИНАР -ТРЕНИНГЕ по породе
                            Вельш-Корги-Пемброк,спикером семинара была заводчик с мировым именем Марина Волкова
                            (питомник "Andvol")</p>
                          <p>Семинар состоял из двух частей. Спикер рассказывала о современных типах породы,о судействе,
                            а также на примере собак участников рассказывала как презентовать свою собаку.</p>
                          <p>Мы познакомились с очень интересными людьми, рассказали про наш корм АмиГолд, а также
                            поделились опытом ухода именно за этой замечательной породой</p>
                          <a class="faq-list__item-link" href="">Полный фотоотчет в VK</a>
                        </div>
                        <div class="faq-list__item-body-media">
                          <div class="swiper faq-item-slider">
                            <div class="swiper-wrapper">
                              <img class="swiper-slide" src="img/people.png" alt="">
                              <img class="swiper-slide" src="img/people.png" alt="">
                              <img class="swiper-slide" src="img/people.png" alt="">
                              <img class="swiper-slide" src="img/people.png" alt="">
                            </div>
                            <div class="swiper-button swiper-button_prev"></div>
                            <div class="swiper-button swiper-button_next"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="faq-list__item">
                    <div class="faq-list__item-header">
                      <span class="faq-list__item-date">18 ноября 2022</span>
                      <p class="faq-list__item-title">Участие в Национальной Ветеринарной Конференции</p>
                    </div>
                    <div class="faq-list__item-body">
                      <div class="faq-list__item-body-info-and-media">
                        <div class="faq-list__item-body-info">
                          <p>Амигос, привет! 18 ноября 2022 года,побывали на СЕМИНАР -ТРЕНИНГЕ по породе
                            Вельш-Корги-Пемброк,спикером семинара была заводчик с мировым именем Марина Волкова
                            (питомник "Andvol")</p>
                          <p>Семинар состоял из двух частей. Спикер рассказывала о современных типах породы,о судействе,
                            а также на примере собак участников рассказывала как презентовать свою собаку.</p>
                          <p>Мы познакомились с очень интересными людьми, рассказали про наш корм АмиГолд, а также
                            поделились опытом ухода именно за этой замечательной породой</p>
                          <a class="faq-list__item-link" href="">Полный фотоотчет в VK</a>
                        </div>
                        <div class="faq-list__item-body-media">
                          <img src="img/interested2.png" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="faq-list__item">
                    <div class="faq-list__item-header">
                      <span class="faq-list__item-author">Грумер Мария</span>
                      <p class="faq-list__item-title">Краски OPAWZ</p>
                    </div>
                    <div class="faq-list__item-body">
                      <div class="faq-list__item-body-info-and-media">
                        <div class="faq-list__item-body-info">
                          <p>Амигос, привет! 18 ноября 2022 года,побывали на СЕМИНАР -ТРЕНИНГЕ по породе
                            Вельш-Корги-Пемброк,спикером семинара была заводчик с мировым именем Марина Волкова
                            (питомник "Andvol")</p>
                          <p>Семинар состоял из двух частей. Спикер рассказывала о современных типах породы,о судействе,
                            а также на примере собак участников рассказывала как презентовать свою собаку.</p>
                          <p>Мы познакомились с очень интересными людьми, рассказали про наш корм АмиГолд, а также
                            поделились опытом ухода именно за этой замечательной породой</p>
                          <a class="faq-list__item-link" href="">Полный фотоотчет в VK</a>
                        </div>
                        <div class="faq-list__item-body-media">
                          <div class="swiper faq-item-slider">
                            <div class="swiper-wrapper">
                              <img class="swiper-slide" src="img/people.png" alt="">
                              <img class="swiper-slide" src="img/people.png" alt="">
                              <img class="swiper-slide" src="img/people.png" alt="">
                              <img class="swiper-slide" src="img/people.png" alt="">
                            </div>
                            <div class="swiper-button swiper-button_prev"></div>
                            <div class="swiper-button swiper-button_next"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="tabs__body">
              <div class="tabs__content tabs__content_active">
                <div class="tabs__content-group">
                  <div class="tabs__content-group-body" v-html="product.content">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  </main>
</template>


<style scoped>
.product__info-block {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>
