import axios from "axios";
import tokenService from "@/services/token.service";

export const helpService = {
  async getSection(href) {
    try {
      const token = await tokenService.getToken()

      const result = await axios(process.env.VUE_APP_API_URL + '/api/relative/section', {
        method: 'POST',
        data: {section: href},
        headers: {
          'Origin': process.env.VUE_APP_ORIGIN,
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Methods': 'GET,OPTIONS,POST',
          'Access-Control-Allow-Headers': 'Accept, Content-Type, Authorization, Access-Control-Allow-Origin',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })

      if (result.status === 200) {
        return result.data;
      }

    } catch (e) {
      return e.message
    }
  },

  async getBrandSection(href) {
    try {
      const token = await tokenService.getToken()

      const result = await axios(process.env.VUE_APP_API_URL + '/api/relative/brand', {
        method: 'POST',
        data: {section: href},
        headers: {
          'Origin': process.env.VUE_APP_ORIGIN,
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Methods': 'GET,OPTIONS,POST',
          'Access-Control-Allow-Headers': 'Accept, Content-Type, Authorization, Access-Control-Allow-Origin',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      if (result.status === 200) {
        return result.data;
      }

    } catch (e) {
      return e.message
    }
  },

  async getTagsSection(href) {
    try {
      const token = await tokenService.getToken()

      const result = await axios(process.env.VUE_APP_API_URL + '/api/relative/tags', {
        method: 'POST',
        data: {section: href},
        headers: {
          'Origin': process.env.VUE_APP_ORIGIN,
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Methods': 'GET,OPTIONS,POST',
          'Access-Control-Allow-Headers': 'Accept, Content-Type, Authorization, Access-Control-Allow-Origin',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      if (result.status === 200) {
        return result.data;
      }

    } catch (e) {
      return []
    }
  }
}
