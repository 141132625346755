import {httpService} from "@/services/api/http.service";

export const reviewService = {

    async list()
    {
        const result = await httpService.get('review/index');

        if(result.status === 200){
          return result.data
        }
        return []
    }
}
