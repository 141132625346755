
export const clearHelper = {
    html(text){
        return text.replace(/(<([^>]+)>)/gi,'');
    },

    split(text, count){
       return  text.slice(0, text.indexOf('.')+1)
    }
}
