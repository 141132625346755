<script setup>

</script>

<template>
 <section>
   <div class="container">
      <div style="margin: 30px;color:red">
     <h1>Внимание!!!</h1>
     <div>Ссылка на оплату устарела, запросите у менеджера новую ссылку</div>
      </div>
   </div>
 </section>
</template>

<style scoped lang="scss">

</style>
