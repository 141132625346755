import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import HomePage from '@/views/HomePage'
import Catalog from "@/views/Catalog";
import authService from "@/services/auth.service";
import Contact from "@/views/Contact";
import WhereWash from "@/views/WhereWash";
import Delivery from "@/views/Delivery";
import About from "@/views/About";
import News from "@/views/News";
import Review from "@/views/Review";
import Akcii from "@/views/Akcii";
import Article from "@/views/Article";
import VideoShool from "@/views/VideoShool"
import Partner from "@/views/Partner";
import Cart from "@/views/Cart";
import Search from "@/views/Search";
import Product from "@/views/Product";
import Question from "@/views/Question";
import ArticleOne from '@/views/ArticleOne';
import Checkout from "@/views/Checkout";
import ErrorPage from "@/views/ErrorPage";
import OldPay from "@/views/OldPay";

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: HomePage
  },
  {
    path: '/catalog/:href',
    name: 'catalog',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: Catalog
  },
  {
    path: '/product/:href',
    name: 'product',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: Product,
    props: true
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: Contact
  },
  {
    path: '/wash',
    name: 'wash',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: WhereWash
  },
  {
    path: '/pay',
    name: 'pay',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: Delivery
  },
  {
    path: '/pay/:href',
    name: 'oldpay',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: OldPay
  },
  {
    path: '/about',
    name: 'about',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: About
  },
  {
    path: '/news',
    name: 'news',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: News,
    children:[
      {
      name: 'one',
      path: ':id',
      props: true,
    }
    ]
  },
  {
    path: '/reviews',
    name: 'reviews',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: Review
  },
  {
    path: '/akcii',
    name: 'akcii',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: Akcii
  },
  {
    path: '/article',
    name: 'article',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: Article,
  },
  {
    name: 'articleBlock',
    component: ArticleOne,
    path: '/article/:id',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    //props: true
  },
  {
    path: '/video',
    name: 'video',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: VideoShool
  },
  {
    path: '/partner',
    name: 'partner',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: Partner
  },
  {
    path: '/cart',
    name: 'cart',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: Cart
  },
  {
    path: '/search',
    name: 'search',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: Search
  },
  {
    path: '/question',
    name: 'question',
    meta: {title: 'Шампуни для груминга Davis, Opazw, корма для животных Амиголд, Амилайн Про'},
    component: Question
  },
  {
    path: '/checkout',
    name: 'checkout',
    meta: {title: 'Оформление заказа'},
    component: Checkout
  },
  {
    path: "/:pathMatch(.*)*",
    name: 'error',
    meta: {title: '404 Не найдено'},
    component: ErrorPage
  }


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // возвращаем требуемую позицию прокрутки
    return { left: 0, top: 0 }
  }
})

router.beforeEach(async (to, from, next) => {

  if (!localStorage.getItem('authToken_amigo_'+process.env.NODE_ENV)) {
    const user = await authService.login({
      login: 'guest',
      password: 'guestGuest8'
    })
    if(user && user.access_token !== undefined){
      localStorage.setItem('authToken_amigo_'+process.env.NODE_ENV, user.access_token)
    }
  }

  document.title = `${to.meta.title}`;
  next();
})

export default router
