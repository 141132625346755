<script setup>

</script>

<template>
  <section>
    <div class="container">
      <h1>По вашему запросу ничего не найдено. </h1>
    </div>
  </section>

</template>

<style scoped lang="scss">

</style>
