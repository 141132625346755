<template>
  <section class="thanks section" v-if="show">
    <div class="thanks__container container">
      <div class="thanks__inner">
        <img class="thanks__img" src="img/icons/check-green.png" width="68" height="68" alt="">
        <h4 class="thanks__title">Ваш заказ успешно оформлен!</h4>
        <p class="thanks__description">В ближайшее время мы с Вами свяжемся.</p>
        <a class="thanks__btn btn btn_outline-blue" href="/">Вернуться на главную</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['show']
};
</script>

<style scoped>
.thanks {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.thanks__container {
  text-align: center;
}

.thanks__img {
  margin-bottom: 10px;
}

.thanks__title {
  font-size: 24px;
  margin-bottom: 10px;
}

.thanks__description {
  font-size: 16px;
  margin-bottom: 20px;
}

.thanks__btn {
  text-decoration: none;
}
</style>