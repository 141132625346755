<script setup>
import BreadCrumbs from "@/components/block/BreadCrumbs";
import {reviewService} from "@/services/review.service";
import {onMounted, ref} from "vue";
import ReviewCard from '@/components/block/ReviewCard'

const outLinks = [
  {name: 'Ваши отзывы', href: 'revie'}
]

const title = 'Ваши отзывы'

const loadReviews = async() => await reviewService.list()

const reviews = ref([])

onMounted(async () => {
    reviews.value = await loadReviews()
})

const openNews = ref(0)

function toogleNews(id)
{
  if(openNews.value === id){
    openNews.value = 0
  }else{
    openNews.value = id
  }

}


</script>

<template>
  <main class="main">
    <BreadCrumbs :outLinks="outLinks"/>

    <section class="section">
      <div class="container">
        <div class="section-header section-header_low-bottom-margin">
          <h2 class="section-header__title">{{ title }}</h2>
        </div>

        <div class="faq-list faq-list_dropdown">

          <div class="faq-list__item"
            v-for="item in reviews" :key="item.id"
               :class="{active: openNews === item.id}"
          >
            <ReviewCard :review="item" @readId="toogleNews(item.id)" />
          </div>
        </div>
      </div>
    </section>

  </main>
</template>


<style scoped lang="scss">

</style>
