import {httpService} from "@/services/api/http.service";
import { useOrderStore } from "@/stores/orderStore";
import { useBasketStore } from "@/stores/basketStore";

export const orderService = {
    async create()
    {
      const order = useOrderStore()
      const cart = useBasketStore()

      const result = await httpService.post('order/create', {
          source: 1,
          order: order.order,
          cart: cart.cart,
          discount: cart.discount
      });

      return result
    }
}
