<script>
export default {
  name: "SortWhereWash"
}
</script>
<template>
  <!--          <form class="filter filter_low-bottom-margin">
            <div class="filter__item" style="flex-grow: 0">359 вопросов</div>
            <div class="filter__item" style="flex-grow: 0">15  городов</div>
            <div class="filter__item" style="flex-grow: 1">
              <input type="text" placeholder="Поиск">
              <svg width="16" height="16">
                <use href="img/icons.svg#search" fill="#8C8C8E"></use>
              </svg>
            </div>
            <div class="select filter__item">
              <select>
                <option value="" disabled selected>Выберите город</option>
                <option value="">Москва</option>
                <option value="">Санкт-Петербург</option>
              </select>
            </div>
          </form>-->
</template>


<style scoped>

</style>
