<script setup>
import {ref, defineProps, onMounted} from 'vue'
import {useBasketStore} from "@/stores/basketStore";
import ImageCard from "@/components/global/ImageCard";
import { productHelper } from "@/components/helpers/product.helper";
// import LabelPromo from "@/components/global/LabelPromo";

const cart = useBasketStore()

const props = defineProps({
  product: {
      type: Object
  }
})

const currentPrice = ref({
  price: 0,
  basicPrice: 0,
  discount: 0,
  typeDiscount: ''
})

onMounted(() => {
  currentPrice.value = productHelper.discountPrice(props.product)
})

function setCount(item, count)
{
  item.count += count
  if(item.count < 0){
    item.count = 0
  }
  cart.calculationDiscount()
  currentPrice.value = productHelper.discountPrice(props.product)
}

function setValue(item, unit)
{
  if(item.name === 'Вес'){
    return item.value_int + unit;
  }
  return item.value;
}

function deleteProduct(item)
{
  cart.delete(item)
  cart.calculationDiscount()
}

function finalPrice(item)
{
  if(item.available_akcii === 1){
    return item.newprice
  }
  if(cart.discount.gallon.rate > 0){
    return item.price - (item.price / 100 * cart.discount.gallon.rate)
  }
  return item.price
}
</script>

<template>
  <ImageCard :image="{
                name: props.product.img,
                width: 270,
                height:150,
                class:'products-list__item-img',
                href: '',
                id: props.product.id,
              }"/>

  <div class="products-list__item-info">
<!--    <LabelPromo v-if="currentPrice.typeDiscount !== ''"  :label="{ name:currentPrice.typeDiscount,visible:true,color: 'basic', class:'products-list__item-info-status'}" />-->

    <p class="products-list__item-info-title">
      <span class="blue">{{ props.product.vendor_name }}</span>. {{ props.product.name }}</p>
    <div class="products-list__item-info-details">
      <i v-for="attribute in props.product.attributesSets">
        <div class="products-list__item-info-details-col cart__item-info-details_key">{{
            attribute.name
          }}:
        </div>
        <div class="products-list__item-info-details-col cart__item-info-details_value">
          {{ setValue(attribute, props.product.unit)}}
        </div>
      </i>
    </div>
  </div>
  <div class="products-list__item-controls">
    <div class="products-list__item-controls-child">
      <div class="products-list__item-btn-count-group">
        <button @click="setCount(props.product, -1)" class="count-btn count-decrease">-</button>
        <input  class="count" type="text" :value="props.product.count">
        <button @click="setCount(props.product, +1)" class="count-btn count-increase">+</button>
      </div>
      <span class="products-list__item-price">{{ currentPrice.price }} ₽</span>
    </div>
    <div class="products-list__item-controls-child">
                  <span
                    v-if="(currentPrice.discount > 0)"
                    class="products-list__item-other">скидка {{ currentPrice.discount }}% &nbsp;<s>{{ currentPrice.basicPrice }}₽</s>
                  </span>
      <button class="products-list__item-btn-delete" @click="deleteProduct(props.product)">
        <svg width="25" height="25">
          <use href="img/icons.svg#trash"></use>
        </svg>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cart .products-list__item-controls-child:nth-child(2){
  justify-content: flex-end;
}
</style>
