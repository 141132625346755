<template>
  <div class="recommended-articles-list">
    <router-link :to="item.href"  v-for="item in articleList"
                 :key="item.id"
                 class="recommended-articles-list__item"
    >
      <img class="recommended-articles-list__item-img" :src="item.img"
           style="width: 20rem; top: 20px; right: -4em" alt="">
      <div class="recommended-articles-list__item-info">
        <span class="recommended-articles-list__item-tag"> {{ item.titleSection }}</span>
        <p class="recommended-articles-list__item-title">{{ item.name }}</p>
        <p class="recommended-articles-list__item-author"> {{ item.autor }} </p>
        <span class="recommended-articles-list__item-details">
						<img :src="item.icons" alt="">
						{{ item.iconsTime }}
					</span>
      </div>
    </router-link>
  </div>
</template>

<script setup>
    import { advertService } from "@/services/advert.service";

    const articleList = await advertService.getAllList()

    const itemArticle = {
      href: '',
      img: '',
      titleSection: '',
      name: '',
      autor: '',
      icons: '',
      iconsTime: ''
    }

</script>

<style scoped>

</style>
