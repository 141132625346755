import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { Toaster } from "@meforma/vue-toaster";
import App from './App.vue'
import router from './router'
import Paginate from "vuejs-paginate-next";
import VueYandexMetrika from 'vue3-yandex-metrika'



const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

createApp(App)
  .use(Paginate)
  .use(Toaster, {
    position: "top-right",
    duration: 2000,
  })
  .use(VueYandexMetrika, {
    id: 43684559,
    router: router,
    env: process.env.NODE_ENV,
    options: {clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor: true,}
  })
  .use(router)
  .use(pinia)
  .mount('#app')


